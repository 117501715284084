// import i18n from '@/i18n/index'

export default {
  basicInformation: [
    { prop: 'charge_point' },
    { prop: 'payment_method' },
    { prop: 'started_at' },
    { prop: 'status' },
    { prop: 'stopped_at' },
    { prop: 'total_amount' },
    { prop: 'total_kwh' },
    { prop: 'user' }
  ]
}
